import React, { Fragment } from 'react';
import { PageProps } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import Link from '../../../components/Link/Link';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';

type Props = PageProps & WithTranslation;

const CCPRenew: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('renew.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.LIGHT_GREEN,
        heroImage: {
          fileName: `hero-image-ccp-${activeLocaleTag}.jpg`,
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="CCP">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-100">
            <div className="CCP__generic-gap">
              <h6>{t('renew.introBlock.body')}</h6>
            </div>
            <div className="CCP__generic-gap">
              <h3>{t('renew.rules.heading')}</h3>
              <h6>{t('renew.rules.headingThree')}</h6>
              {(t('renew.rules.body', { returnObjects: true }) as any[]).map((rule) => (
                <Fragment key={rule.heading}>
                  <br />
                  <h6>{rule.heading}</h6>
                  <p>
                    <Trans
                      t={t}
                      values={{
                        privacyStatement: RouteKeys.PRIVACY_STATEMENT.INDEX.path[activeLocaleTag],
                        renewalsEmail: t(`${LocaleNameSpaceKeys.COMMON}:emails.renewals`),
                      }}
                      components={[
                        <ExternalLink
                          href={`mailto: ${t(`${LocaleNameSpaceKeys.COMMON}:emails.renewals`)}`}
                          aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${t(
                            `${LocaleNameSpaceKeys.COMMON}:emails.renewals`
                          )}`}
                          key="email"
                        />,
                        <Link
                          to={getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)}
                          key="privacy"
                        />,
                      ]}
                    >
                      {rule.description}
                    </Trans>
                  </p>
                </Fragment>
              ))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CCP)(CCPRenew);
